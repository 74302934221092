<template>
    <div class="tick" ref="tick" >
        <div data-repeat="true" data-layout="horizontal fit" data-transform="preset(d, h, m, s) -> delay">
            <div class="tick-group">
                <div data-key="value" data-repeat="true" data-transform="pad(00) -> split -> delay">
                    <span data-view="flip"></span>
                </div>
                <span data-key="label" data-view="text" class="tick-label"></span>
            </div>
        </div>
    </div>
</template>

<script>
    import Tick from "@pqina/flip";
    import "@pqina/flip/dist/flip.min.css";

    export default {
        name: "Flip",
        props: {
            timestamp: Number,
            countDownPreview:  Number
        },
        data(){
            return{
                value: null
            }
        },
        watch: {
            value(newValue) {
                this._tick.value = newValue;
            }
        },
        mounted() {
            this._tick = Tick.DOM.create(this.$refs.tick);
            const countDownPreview = this.countDownPreview ? this.countDownPreview : 0;
            const finalTimestamp = this.timestamp - (countDownPreview * 60); 
            let date = new Date(finalTimestamp * 1000);
            let counter = Tick.count.down(date);
            counter.onupdate = (value) => {
                this._tick.value = value;
            };
            counter.onended = (_) => this.$emit("timeEnded")
        },
        destroyed() {
            // Tick.DOM.destroy(this.$refs.tick);
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .tick {
        max-width: 40rem;
        margin: 0 auto;
        font-size:1rem;
        white-space:nowrap;
    }

    .tick-flip,.tick-text-inline {
        font-size:2.5em;
    }

    .tick-label {
        margin-top:1em;font-size:1em;
    }

    .tick-char {
        width:1.5em;
    }

    .tick-text-inline {
        display:inline-block;text-align:center;min-width:1em;
    }

    .tick-text-inline+.tick-text-inline {
        margin-left:-.325em;
    }

    .tick-group {
        margin:0 .5em;text-align:center;
    }

    .tick-text-inline {
        color: #595d63 !important;
    }

    .tick-label {
        color: #595d63 !important;
    }

    .tick-flip-panel {
        color: #fff !important;
    }

    .tick-flip {
    }

    .tick-flip-panel-text-wrapper {
        line-height: 1.45 !important;
    }

    .tick-flip-panel {
        background-color: #3c3e3c !important;
    }

    .tick-flip {
        border-radius:0.12em !important;
    }
</style>
